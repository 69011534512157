import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsPartial,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead
} from '../../../../components';

const KeyboardShortcut = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.KeyboardShortcut;
    return <Component {...props} />;
  }
});

import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageWithSubNav pageType="design" title="Toggle" designCode subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Modules" tierThree="Toggle" />
        <PageNavigation
          links={['Behavior', 'View Modes', 'Usage', 'Microcopy', 'Accessibility', 'Related', 'Platform Support']}
        />
        <Section title="Behavior">
          <SectionSubhead>Selection</SectionSubhead>
          <Paragraph>
            Toggles are meant to be used as radio selectors, meaning that <strong>a single option</strong> can be
            selected at a time. If one of the toggle buttons is selected the others will become unselected.
          </Paragraph>
        </Section>

        <Section title="View Modes">
          <Paragraph>
            Toggles can be viewed in the <strong>collapsed</strong> and <strong>preview</strong> view modes for{' '}
            <Link href="../../data-card/design#View%20Modes">view mode for data cards</Link>.
          </Paragraph>
          <SectionSubhead>Collapsed</SectionSubhead>
          <Paragraph>
            When displayed in the <strong>collapsed</strong> view mode, the toggle displays a set of toggle buttons that
            can be selected and de-selected.
          </Paragraph>
          <SectionSubhead>Preview</SectionSubhead>
          <Paragraph>
            In the <strong>preview</strong> view mode, related toggles can be displayed in a list with left-aligned
            labels.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <Paragraph>
            Toggles are part of the module system, and should only be used within data cards.{' '}
            <strong>Toggles should be used to filter content between two and five data sets</strong> which are meant to
            be viewed separately.
          </Paragraph>
          <DontDo
            dontText="use toggles outside of data cards."
            doText="use toggles within the module system."
            imgFilename="toggle-modules"
          />
          <DontDo
            dontText="use toggles in varying orientations."
            doText="use toggles horizontally."
            imgFilename="toggle-orientation"
          />
          <DontDo
            dontText="use toggles in preview, expanded, or full view states."
            doText="use toggles in collapsed view state."
            imgFilename="toggle-viewmode"
          />
          <DontDo
            dontText="use toggles for extensive data sets."
            doText="use toggles for two to five options."
            imgFilename="toggle-count"
          />
          <DontDo
            dontText="enable selecting multiple toggle buttons at once."
            doText="disable toggle buttons with no data."
            imgFilename="toggle-singleselect"
          />
          <DontDo
            dontText="use toggle lists to group toggles that are not directly related."
            doText="use toggle lists to quickly filter related values."
            imgFilename="toggle-togglelist"
          />
        </Section>
        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            Toggle button labels should be as short as possible without becoming arbitrary or difficult to understand.
            Ideally, these labels should only be a single character, but if necessary to convey meaning, two characters
            are acceptable.
          </Paragraph>
          <DontDo
            dontText="use verbose labels in toggle buttons."
            doText="use only one or two characters in toggle buttons."
            imgFilename="toggle-characters"
          />
        </Section>

        <Section title="Accessibility">
          <SectionSubhead>Use descriptions when the label isn't enough (web)</SectionSubhead>
          <Paragraph>
            Sometimes the button label alone isn't enough to properly convey the meaning intended. For example, the ODK
            toggle might immediately make sense to some coaches or players, but for others it could be confusing. By
            adding descriptions such as “offense”, “defense”, and “special teams” we can provide that extra information
            via aria attributes for screen readers and tooltips.
          </Paragraph>
          <SectionSubhead>Keyboard support (web)</SectionSubhead>
          <Paragraph>
            Tab navigation is fully supported by default. Users can press <KeyboardShortcut>enter</KeyboardShortcut> to
            toggle the selection of an item.
          </Paragraph>
          <AccessibilityAuditComponentResultsPartial componentName="Modules / Toggle" />
        </Section>

        <Section title="Related">
          <h3 className="uni-margin--one--bottom">
            <Link href="../../data-card/design">Data Card</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../card-group/design">Card Group</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design#Choosing%20a%20Visualization">Data Visualizations</Link>
          </h3>
          <h3 className="uni-margin--one--bottom">
            <Link href="../../design">Module System</Link>
          </h3>
          <h3>
            <Link href="../../../../guidelines/accessibility/design">Accessibility</Link>
          </h3>
        </Section>

        <Section title="Platform Support">
          <Paragraph>The toggle is available for React and React Native.</Paragraph>
        </Section>
      </PageWithSubNav>
    );
  }
}
